import React from 'react'
import useSWR from 'swr'

import { fromTs } from '../components/Helpers'
import { CreatePlayerButton, HireCharacter, TavernStop } from '../components/PlayerActions'

function TavernPage (props) {

  const fetchPlayer = async (...args) => {
    return await props._near.contract.get_player({"player_id": props._near.accountId})
  }

  const { data: player } = useSWR('player', fetchPlayer, { errorRetryInterval: 250 })

  let queue
  let idle
  if (player) {
    console.log(player)
    queue = player.tavern.queue.map((data, index) => {
      console.log(data)
      const stats = data[0].stats.map((data, index) => {
        return (<div className='row' key={index}>
        <div className='col-2'>{data[0]}</div>
        <div className='col-2'>{data[1]}</div>
        </div>)
      })
      return (
        <div key={index}>
          <div className='row'>
          <div className='col-2'>Name</div>
          <div className='col-2'>{data[0].name}</div>
          </div>

          <div className='row'>
          <div className='col-2'>Class</div>
          <div className='col-2'>{data[0].class}</div>
          </div>
          {stats}
          <HireCharacter {...props} disabled={player.storage.characters.length === player.storage.num_characters_slots} id={index} name={data[0].name}/>
        </div>
      )
    })

    idle = player.tavern.appeal.state[0] === "Idle"
    /*appeal = player.tavern.appeal.production.map((data, index) => {
      const units = data[1].cost.units.map((data, index) => {
        return (<div className='row' key={index}>
        <div className='col-2'>{data[0]}</div>
        <div className='col-2'>{data[1]}</div>
        </div>)
      })
      const duration = data[1].cost.duration
      return (
        <div key={index}>
          <h5>{data[0]}</h5>
          <div className='col-2'>Cost</div>
          {!units.length ? <div className='col-2'>free</div> : units}
          <div className='row'>
          <div className='col-2'>Duration</div>
        <div className='col-2'>{fromTs(duration)} sec</div>
          </div>
          {idle && <MineWork {...props} unit={data[0]} amount="10000"/>}
        </div>
      )
    })*/
  }
  return player ? (
    <div
      className='container g-0'
    >
      <h3 className='mt-3'>
        Capacity of characters hired
        
      </h3>
      {player.storage.characters.length} of {player.storage.num_characters_slots}
      <h3 className='mt-3'>
        Queue of character to hire
        
      </h3>
      {queue}

      <h3 className='mt-3'>
        State  
      </h3>
      {idle ? "Idle" : 
      <div>
        <div className='col-2'>{player.tavern.appeal.state[0]}</div>
        <div className='col-2'>{fromTs(player.tavern.appeal.state[1])} sec</div>
        <TavernStop {...props}/>
        </div>}

      </div>
  ) : (    <CreatePlayerButton {...props}/>)
}

export default TavernPage
