import React from 'react'

const NEAR = '\u24C3\u202F'

const fromNear = (s) => parseFloat(s) / 1e24 || 0
const fromTs = (s) => (parseFloat(s) / 1e9).toFixed(1) || 0

const qq = '//'

function loader () {
  return (
  // key='1' is needed by InfiniteScroll
    <div className='d-flex justify-content-center' key='1'>
      <div className='spinner-grow' role='status'>
        <span className='visually-hidden'>Loading...</span>
      </div>
    </div>
  )
}

async function getPlayer (props) {
  
}

export { NEAR, fromNear, fromTs, loader, qq, getPlayer }
