import React from 'react'
import useSWR from 'swr'

import { fromTs } from '../components/Helpers'
import { CreatePlayerButton, MineUpgrade,  MineStop, MineWork } from '../components/PlayerActions'

function MinePage (props) {

  const fetchPlayer = async (...args) => {
    return await props._near.contract.get_player({"player_id": props._near.accountId})
  }

  const { data: player } = useSWR('player', fetchPlayer, { errorRetryInterval: 250 })

  let idle
  let upgrades
  let production
  let units // just test for warnings

  if (player) {
    console.log(player)
    idle = player.mine.state[0] === "Idle"
    production = player.mine.production.map((data, index) => {
      const units = data[1].cost.units.map((data, index) => {
        return (<div className='row' key={index}>
        <div className='col-2'>{data[0]}</div>
        <div className='col-2'>{data[1]}</div>
        </div>)
      })
      const duration = data[1].cost.duration
      return (
        <div key={index}>
          <h5>{data[0]}</h5>
          <div className='col-2'>Cost</div>
          {!units.length ? <div className='col-2'>free</div> : units}
          <div className='row'>
          <div className='col-2'>Duration</div>
        <div className='col-2'>{fromTs(duration)} sec</div>
          </div>
          {idle && <MineWork {...props} unit={data[0]} amount="10000"/>}
        </div>
      )
    })
    upgrades = player.mine.upgrades.map((data, index) => {
      const duration = data[1].duration
      const units = data[1].units.map((data, index) => {
        return (<div className='row' key={index}>
        <div className='col-2'>{data[0]}</div>
        <div className='col-2'>{data[1]}</div>
        </div>)
      })
      return (
        <div key={index}>
          <h5>{data[0]}</h5>
          <div className='col-2'>Cost</div>
          {!units.length ? <div className='col-2'>free</div> : units}
          <div className='row'>
          <div className='col-2'>Duration</div>
        <div className='col-2'>{fromTs(duration)} sec</div>
          </div>
          {idle && <MineUpgrade {...props} event={data[0]}/>}
        </div>
      )
    })


  }
  return player ? (
    <div
      className='container g-0'
    >
      <h3 className='mt-3'>
        State  
      </h3>
      {idle ? "Idle" : 
      <div>
        <div className='col-2'>{player.mine.state[0]}</div>
        <div className='col-2'>{fromTs(player.mine.state[1])} sec</div>
        <MineStop {...props}/>
        </div>}
        <h3 className='mt-3'>
        Capacity  
      </h3>
      {player.mine.capacity}
        <h3 className='mt-3'>
        Production  
      </h3>
      {production}
      <h3 className='mt-3'>
        Upgrades  
      </h3>
      {upgrades}

      </div>
  ) : (    <CreatePlayerButton {...props}/>)
}

export default MinePage
