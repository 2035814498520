import React, { useState } from 'react'
import useSWR from 'swr'

import { fromTs } from '../components/Helpers'
import { CreatePlayerButton, DungeonBattleStart, GAS } from '../components/PlayerActions'

function DungeonPage (props) {
  const [lastBattle, setLastBattle] = useState(null)
  const [chosenCharacters, setChosenCharacters] = useState(new Set())

  function DungeonBattleEnd (props) {
    async function f(e) {
      e.preventDefault()
      setLastBattle(await props._near.contract.dungeon_battle_end({'event': props.event}, GAS))
      console.log(lastBattle)
    }
    return (
      <button
        className='btn btn-success'
        onClick={(e) => f(e)}
      >
          Return
      </button>
    )
  }
  const fetchPlayer = async (...args) => {
    return await props._near.contract.get_player({"player_id": props._near.accountId})
  }

  const { data: player } = useSWR('player', fetchPlayer, { errorRetryInterval: 250 })

  console.log('11', lastBattle, chosenCharacters)

  let battles
  let characters
  let chooseCharacters
  if (player) {
    console.log(player)
    chooseCharacters = player.dungeon.num_troops > 0
    battles = player.dungeon.battles.map((data, index) => {
      const idle = data[1].state[0] === "Idle"
      return (
        <div key={index}>
          <div className='row'>
            <div className='col-2'>Name</div>
           <div className='col-2'>{data[0]}</div>
           </div>

           <div className='row'>
            <div className='col-2'>Number of waves</div>
           <div className='col-2'>{data[1].waves_count}</div>
           </div>

           <div className='row'>
            <div className='col-2'>Duration</div>
           <div className='col-2'>{fromTs(data[1].duration)} sec</div>
           </div>

           <div className='row'>
            <div className='col-2'>Deadly?</div>
           <div className='col-2'>{data[1].is_deadly ? "yes" : "no"}</div>
           </div>

           <div className='row'>
            <div className='col-2'>State</div>
           <div className='col-2'>{idle ? "Idle" : fromTs(data[1].state[1])+" sec"}</div>
           <div className='col-4'>{idle ? <DungeonBattleStart {...props} event={data[0]} characterIds={Array.from(chosenCharacters)}/> : <DungeonBattleEnd {...props} event={data[0]}/>}</div>
           </div>

           
        </div>
      )
    })
    characters = player.storage.characters.map((data, index) => {
      const charId = data.id
      const stats = data.stats.map((data, index) => {
        return (<div className='row' key={index}>
        <div className='col-2'>{data[0]}</div>
        <div className='col-2'>{data[1]}</div>
        </div>)
      })
      const slots = data.slots.map((data, index) => {
        return (<div className='row' key={index}>
        <div className='col-2'>{data[0]}</div>
        <div className='col-2'>{data[1]}</div>
        </div>)
      })
      return (
        <div className='p-3' key={index}>
          <div className='row'>
          <div className='col-2'>Name</div>
          <div className='col-2'>{data.name}</div>
          </div>

          <div className='row'>
          <div className='col-2'>Class</div>
          <div className='col-2'>{data.class}</div>
          </div>
          {stats}
          <h4>
            Slots
          </h4>
          {slots}
          {chooseCharacters &&
          <div className="form-check">
            <input className="form-check-input" type="checkbox" value="" id={"character"+index} onChange={(e) => {
              !chosenCharacters.has(charId) ? setChosenCharacters(prev => new Set(prev.add(charId)))  : setChosenCharacters(prev => new Set([...prev].filter(x => x !== charId)))
            }} />
            <label className="form-check-label" htmlFor={"character"+index}>
              Add to the troop
            </label>
          </div>}
        </div>
      )
    })
    
  }


  let lastBattleView
  if (lastBattle) {

    const units = lastBattle.reward.units.map((data, index) => {
      return (
        <div key={index} className='row'>
          <div className='col-2'>{data[0]}</div>
          <div className='col-2'>{data[1]}</div>
        </div>
      )
    })
    const items = lastBattle.reward.items.map((data, index) => {
      return (
        <div key={index}>
          {data}
        </div>
      )
    })

    lastBattleView = (
      <div>
        <h3 className='mt-3'>
          Last battle
        </h3>
        <div className='row'>
          <div className='col-2'>Win</div>
          <div className='col-2'>{lastBattle.win ? "yes" : "no"}</div>
        </div>
        <div className='row'>
          <div className='col-2'>Total exp received</div>
          <div className='col-2'>{lastBattle.total_exp}</div>
        </div>
        <h5 className='mt-3'>
          Items
        </h5>
        {items}
        <h5 className='mt-3'>
          Units
        </h5>
        {units}
        
      </div>
    )
  }

  return player ? (
    <div
      className='container g-0'
    >
      {lastBattleView}
      <h3 className='mt-3'>
        Battles
        
      </h3>
      {battles}
      <h3 className='mt-3'>
        Characters
        
      </h3>
      {characters}

      </div>
  ) : (    <CreatePlayerButton {...props}/>)
}

export default DungeonPage
