import React, { useState } from 'react'
import useSWR from 'swr'

import { CreatePlayerButton, EquipItem, UnequipItem } from '../components/PlayerActions'

function StoragePage (props) {
  const [lastChosen, setLastChosen] = useState(-1)
  console.log(lastChosen)

  const fetchPlayer = async (...args) => {
    return await props._near.contract.get_player({"player_id": props._near.accountId})
  }

  const { data: player } = useSWR('player', fetchPlayer, { errorRetryInterval: 250 })

  let quests
  let characters
  let items
  let units
  if (player) {
    console.log(player)
    quests = player.quests.map((data, index) => {
      return (
        <div key={index} className='row'>
          {data}
        </div>
      )
    })
    characters = player.storage.characters.map((data, index) => {
      console.log(data)
      const charId = data.id
      const stats = data.stats.map((data, index) => {
        return (<div className='row' key={index}>
        <div className='col-2'>{data[0]}</div>
        <div className='col-2'>{data[1]}</div>
        </div>)
      })
      const slots = data.slots.map((data, index) => {
        return (<div className='row' key={index}>
        <div className='col-2'>{data[0]}</div>
        <div className='col-2'>{data[1]}</div>
        {data[1] === "NoItem" ? <div/> : <div className='col-4'><UnequipItem {...props} characterId={charId} slot={data[0]}/></div>}
        </div>)
      })
      return (
        <div className='p-3' key={index}>
          <div className='row'>
          <div className='col-2'>Name</div>
          <div className='col-2'>{data.name}</div>
          <div className='col-2'>
          {lastChosen > -1 ? (<EquipItem {...props} characterId={data.id} itemId={lastChosen}/>) : (<div/>)}
          </div>
          </div>

          <div className='row'>
          <div className='col-2'>Class</div>
          <div className='col-2'>{data.class}</div>
          </div>
          {stats}

          <div className='row'>
          <div className='col-2'>State</div>
          <div className='col-2'>{data.state}</div>
          </div>
          
          <h4>
            Slots
          </h4>
          {slots}

        </div>
      )
    })

    items = player.storage.items.map((data, index) => {
      return (
        <button key={index} className='btn btn-outline-secondary' onClick={(e) => setLastChosen(index)}>
          {data}
        </button>
      )
    })
    units = player.storage.units.map((data, index) => {
      return (
        <div key={index} className='row'>
          <div className='col-2'>{data[0]}</div>
          <div className='col-2'>{data[1]}</div>
        </div>
      )
    })
  }
  return player ? (
    <div
      className='container g-0'
    >
      <h3 className='mt-3'>
        Quests
        
      </h3>
      {quests}
      <h3 className='mt-3'>
        Characters
        
      </h3>
      {characters}

      <h3 className='mt-3'>
      Items
        
      </h3>
      {items}
      <h3 className='mt-3'>
      Units
        
      </h3>
      {units}
      </div>
  ) : (    <CreatePlayerButton {...props}/>)
}

export default StoragePage
