import React from 'react'
import { mutate } from 'swr'

const GAS = '40000000000000'

function CreatePlayerButton (props) {
  async function createPlayer (e) {
    e.preventDefault()
    await props._near.contract.create_player({}, GAS, '100000000000000000000000')
  }
  return (
    <button
      className='btn btn-success w-100 mb-2'
      disabled={!props.signedIn}
      onClick={(e) => createPlayer(e)}
    >
        Create Player
    </button>
  )
}

function HireCharacter (props) {
  async function hireCharacter (e) {
    e.preventDefault()
    await props._near.contract.hire_character({'id': props.id}, GAS)
    mutate('player')
  }
  return (
    <button
      className='btn btn-success mb-2'
      disabled={props.disabled}
      onClick={(e) => hireCharacter(e)}
    >
        Hire {props.name}
    </button>
  )
}

function TavernStop (props) {
  async function f (e) {
    e.preventDefault()
    await props._near.contract.tavern_stop({}, GAS)
    mutate('player')
  }
  return (
    <button
      className='btn btn-success'
      onClick={(e) => f(e)}
    >
        Update
    </button>
  )
}

function EquipItem (props) {
  async function equipItem (e) {
    e.preventDefault()
    await props._near.contract.equip_item({'character_id': props.characterId, 'item_id': props.itemId}, GAS)
    mutate('player')
  }
  return (
    <button
      className='btn btn-success'
      onClick={(e) => equipItem(e)}
    >
        Equip
    </button>
  )
}

function UnequipItem (props) {
  async function unequipItem (e) {
    e.preventDefault()
    await props._near.contract.unequip_item({'character_id': props.characterId, 'slot': props.slot}, GAS)
    mutate('player')
  }
  return (
    <button
      className='btn btn-success'
      onClick={(e) => unequipItem(e)}
    >
        Unequip
    </button>
  )
}

function MineWork(props) {
  async function mineWork (e) {
    e.preventDefault()
    await props._near.contract.mine_work({'unit': props.unit, 'amount':props.amount}, GAS)
    mutate('player')
  }
  return (
    <button
      className='btn btn-success'
      onClick={(e) => mineWork(e)}
    >
        Produce
    </button>
  )
}

function MineUpgrade (props) {
  async function mineUpgrade (e) {
    e.preventDefault()
    await props._near.contract.mine_upgrade({'event': props.event}, GAS)
    mutate('player')
  }
  return (
    <button
      className='btn btn-success'
      onClick={(e) => mineUpgrade(e)}
    >
        Upgrade
    </button>
  )
}

function MineStop (props) {
  async function mineStop (e) {
    e.preventDefault()
    await props._near.contract.mine_stop({}, GAS)
    mutate('player')
  }
  return (
    <button
      className='btn btn-success'
      onClick={(e) => mineStop(e)}
    >
        Stop
    </button>
  )
}

function DungeonBattleStart (props) {
  async function f(e) {
    e.preventDefault()
    await props._near.contract.dungeon_battle_start({'event': props.event, 'character_ids': props.characterIds}, GAS)
    mutate('player')
  }
  return (
    <button
      className='btn btn-success'
      disabled={!props.characterIds.length}
      onClick={(e) => f(e)}
    >
        Battle!
    </button>
  )
}

export { CreatePlayerButton, TavernStop, HireCharacter, EquipItem, UnequipItem, DungeonBattleStart, MineWork, MineUpgrade, MineStop, GAS }


